import styled from "styled-components";
import { darkBlueGrey } from "components/baseColor";

export const WrapperQuantity = styled.div`
  display: grid;
  justify-content: center;
  grid-template-areas:
    "minButton title plusButton"
    "minButton qty plusButton"
    "minButton . plusButton";
`;

export const Title = styled.p`
  grid-area: title;
  margin: 0;
  font-size: 1.1em;
`;

export const MinButton = styled.p`
  grid-area: minButton;
  margin: 0;
  width: 2.5em;
  height: 2.5em;
  border: 0.5px solid ${darkBlueGrey};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    font-size: calc(100% * 3);
    margin-top: -14px;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const PlusButton = styled(MinButton)`
  grid-area: plusButton;

  span {
    font-size: calc(100% * 1.8);
    margin-top: -3px;
  }
`;

export const Quantity = styled.div`
  grid-area: qty
  display: grid;
  grid-auto-flow: row;
  text-align: center;
  border-bottom: 1px solid red;
`;
