import styled from "styled-components";

import { darkBlueGrey } from "components/baseColor";

export const HeaderContainer = styled.div`
  padding-top: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  @media only screen and (max-width: 768px) {
    padding: 4rem 2rem 0rem 2rem;
  }

  > h1 {
    text-transform: uppercase;
    color: ${darkBlueGrey};
    margin: 0;
    line-height: 1.28;
    letter-spacing: 2px;
    @media only screen and (max-width: 768px) {
      font-size: 2em;
    }
  }

  > p {
    color: ${darkBlueGrey};
    @media only screen and (max-width: 768px) {
      font-size: 1.15em;
    }
  }
`;
