import React from "react";
import { HeaderContainer } from "./styles";

const HeaderSection = () => {
  return (
    <HeaderContainer>
      <h1>REVIEW PRODUCTS</h1>
      <p>Review your products selection to suit your business’ scope.</p>
    </HeaderContainer>
  );
};

export default HeaderSection;
