import React from "react";
import { DetailContainer } from "./styles";
import DetailCard from "./components/DetailCard";

const DetailSection = () => {
  return (
    <DetailContainer>
      <DetailCard />
    </DetailContainer>
  );
};

export default DetailSection;
