import React from "react";

import Layout from "components/Layout";
import { WrapperReview } from "./styles";
import SEO from "components/seo";
import HeaderSection from "./HeaderSection";
import DetailSection from "./DetailSection";

function CheckoutReviewPage({ title }) {
  return (
    <>
      <SEO title="Custom Checkout Review" />
      <Layout>
        <WrapperReview>
          <HeaderSection />
          <DetailSection />
        </WrapperReview>
      </Layout>
    </>
  );
}

export default CheckoutReviewPage;
