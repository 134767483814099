import styled from "styled-components";

export const WrapperReview = styled.div`
  padding: 4rem 8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (max-width: 1024px) {
    align-items: normal;
    padding: 4rem 2rem;
  }
`;
