import React, { useState, useMemo } from 'react'
import {
  Flex,
  WrapperDetailAmounts,
  WrapperCard,
  WrapperRowHeader,
  Column,
  WrapperRowItems,
  WrapperGrandTotal,
  Line,
  Contacts,
  ButtonContainer,
  WrapperPromotional,
  WrapperInput,
  WrapperApply
} from './styles'
import QuantityButton from '../QuantityButton'
import Button from 'components/Button'
import InputText from 'components/InputText'

const DASHBOARD_FEATURES = [
  {
    title: 'Analytics'
  },
  {
    title: 'Workgroup'
  },
  {
    title: 'Maps'
  },
  {
    title: 'Live Feeds'
  },
  {
    title: 'Attendance System'
  }
]

export const maskedPrice = (price) => {
  const priceReversed = price.split('').reverse().join('')
  let masked = ''
  let counter = 0
  for (let i = 0; i < priceReversed.length; i++) {
    if (counter === 3) {
      counter = 0
      masked = masked + '.' + priceReversed[i]
    } else {
      masked = masked + priceReversed[i]
    }
    counter++
  }

  return masked.split('').reverse().join('')
}

const RowItems = ({
  name,
  title,
  price,
  total,
  isChild = false,
  handleChange,
  index,
  listProducts
}) => (
  <WrapperRowItems>
    <Column width="50%" withPadding={ isChild }>
      <h2>{ title }</h2>
    </Column>
    <Column width="25%">
      <QuantityButton
        name={ name }
        total={ total }
        handleChange={ handleChange }
        data={{ isChild: isChild, title: title, index: index, listProducts: listProducts }}
      />
    </Column>
    <Column width="25%">
      <h2>
        <span style={{ fontWeight: '300', fontSize: '14px' }}>IDR</span>{ ' ' }
        { maskedPrice((parseInt(price) * total).toString()) }
      </h2>
    </Column>
  </WrapperRowItems>
)

const RowWithParents = ({ data, handleChange, index, listProducts }) => (
  <>
    <Flex direction="column">
      <WrapperRowItems>
        <Column width="50%">
          <h2>{ data.title }</h2>
        </Column>
      </WrapperRowItems>
      { data.child.map((el) => (
        <RowItems
          key={ el.title }
          listProducts={ listProducts }
          index={ index }
          title={ el.title }
          total={ el.total }
          price={ el.price }
          name={ el.name }
          isChild={ el.isChild }
          handleChange={ handleChange }
        />
      )) }
    </Flex>
    <Line />
  </>
)

const DashboardFeatures = () => (
  <Flex direction="column">
    <WrapperRowItems>
      <Column width="50%">
        <h2>Dashboard Features</h2>
      </Column>
    </WrapperRowItems>
    { DASHBOARD_FEATURES.map((el) => (
      <WrapperRowItems key={ el.title }>
        <Column width="50%" withPadding={ true }>
          <h2>{ el.title }</h2>
        </Column>
        <Column width="25%"></Column>
        <Column width="25%">
          <h3 style={{ fontWeight: '300' }}>Free</h3>
        </Column>
      </WrapperRowItems>
    )) }
  </Flex>
)

const DetailAmounts = ({ promotionalCode, setPromotionalCode, subtotal, vat }) => {
  const handleChange = (e, name) => {
    e.persist()

    if (name === 'isOpen') {
      setPromotionalCode((prev) => ({ ...prev, [name]: true }))
    } else if (name === 'code') {
      setPromotionalCode((prev) => ({ ...prev, [name]: e.target.value, error: '' }))
    }
  }

  const handleApply = () => {
    if (promotionalCode.code === 'VALID') {
      setPromotionalCode((prev) => ({ ...prev, isApply: true, value: 10000 }))
    } else {
      setPromotionalCode((prev) => ({
        ...prev,
        error: 'Promotional code you entered is not valid.'
      }))
    }
  }

  return (
    <WrapperDetailAmounts>
      <Flex direction="column">
        <WrapperRowItems>
          <Column width="50%">
            <h1>TOTAL AMOUNT</h1>
          </Column>
          <Column width="25%"></Column>
          <Column width="25%"></Column>
        </WrapperRowItems>
        <WrapperRowItems>
          <Column width="50%">
            <h2 style={{ fontWeight: '500' }}>Subtotal</h2>
          </Column>
          <Column width="25%"></Column>
          <Column width="25%">
            <h2>
              <span style={{ fontWeight: '300', fontSize: '14px' }}>IDR</span>{ ' ' }
              { maskedPrice(subtotal.toString()) }
            </h2>
          </Column>
        </WrapperRowItems>
        <WrapperRowItems>
          <Column width="50%">
            <h2 style={{ fontWeight: '500' }}>VAT (11%)</h2>
          </Column>
          <Column width="25%"></Column>
          <Column width="25%">
            <h2>
              <span style={{ fontWeight: '300', fontSize: '14px' }}>IDR</span>{ ' ' }
              { maskedPrice(vat.toString()) }
            </h2>
          </Column>
        </WrapperRowItems>
        <WrapperRowItems>
          <Column width="50%">
            <WrapperPromotional
              isOpen={ promotionalCode.isOpen }
              onClick={ (e) => handleChange(e, 'isOpen') }
            >
              <h2>{ promotionalCode.isOpen ? 'Promotional Code' : 'got any promotional code?' }</h2>
            </WrapperPromotional>
          </Column>
        </WrapperRowItems>
        { promotionalCode.isOpen && (
          <WrapperRowItems>
            <Column width="100%">
              <WrapperApply>
                <WrapperInput>
                  <InputText
                    name="code"
                    value={ promotionalCode.code }
                    onChange={ (e) => handleChange(e, 'code') }
                    error={ promotionalCode.error }
                    onBlur={ () => {} }
                    placeholder=""
                  />
                </WrapperInput>

                <Button type="warning" onClick={ handleApply }>
                  APPLY
                </Button>
              </WrapperApply>
            </Column>
          </WrapperRowItems>
        ) }
        { promotionalCode.code !== '' && promotionalCode.isApply && (
          <WrapperRowItems>
            <Column width="50%">
              <h2>{ promotionalCode.code }</h2>
            </Column>
            <Column width="25%"></Column>
            <Column width="25%">
              <h2 style={{ color: '#11b683' }}>
                <span style={{ fontWeight: '300', fontSize: '14px' }}>IDR</span> -{ ' ' }
                { maskedPrice(promotionalCode.value.toString()) }
              </h2>
            </Column>
          </WrapperRowItems>
        ) }
      </Flex>
    </WrapperDetailAmounts>
  )
}

const DetailCard = () => {
  const [promotionalCode, setPromotionalCode] = useState({
    code    : '',
    value   : 0,
    isOpen  : false,
    isApply : false,
    error   : ''
  })

  const [listProducts, setListProducts] = useState([
    {
      title   : 'Smart Workforce Management',
      total   : 20,
      price   : '100000',
      name    : 'engines',
      isChild : true
    },
    {
      title   : 'Human Detection and Recognition',
      isChild : false,
      child   : [
        {
          title   : 'People Counting',
          total   : 4,
          price   : '50000',
          name    : 'engines',
          isChild : true
        },
        {
          title   : 'Face Recognition',
          total   : 3,
          price   : '300000',
          name    : 'engines',
          isChild : true
        },
        {
          title   : 'Intrusion',
          total   : 2,
          price   : '20000',
          name    : 'engines',
          isChild : true
        },
        {
          title   : 'Face Mask Detection',
          total   : 1,
          price   : '20000',
          name    : 'engines',
          isChild : true
        }
      ]
    },
    {
      title   : 'Vehicle Detection and Recognition',
      isChild : false,
      child   : [
        {
          title   : 'Illegal Parking',
          total   : 1,
          price   : '200000',
          name    : 'engines',
          isChild : true
        },
        {
          title   : 'Vehicle Categorization',
          total   : 1,
          price   : '200000',
          name    : 'engines',
          isChild : true
        },
        {
          title   : 'License Plate Recognition',
          total   : 1,
          price   : '200000',
          name    : 'engines',
          isChild : true
        }
      ]
    },
    {
      title   : 'Smart Device',
      total   : 20,
      price   : '100000',
      name    : 'devices',
      isChild : true
    }
  ])

  const countSubtotal = (listProducts) => {
    let subtotal = 0
    listProducts.forEach((el) => {
      if (!el.isChild) {
        el.child.forEach((ch) => {
          subtotal = subtotal + parseInt(ch.total * parseInt(ch.price))
        })
      } else {
        subtotal = subtotal + parseInt(el.total * parseInt(el.price))
      }
    })
    return subtotal
  }
  const subtotal = useMemo(() => countSubtotal(listProducts), [listProducts])

  const countVat = (subtotal) => (subtotal * 10) / 100
  const memoizedVAT = useMemo(() => countVat(subtotal), [subtotal])

  return (
    <WrapperCard>
      <h2>Products Detail</h2>
      <WrapperRowHeader>
        <Column width="50%">
          <h3>Products</h3>
        </Column>
        <Column width="25%">
          <h3>Quantity</h3>
        </Column>
        <Column width="25%">
          <h3>Total Price</h3>
        </Column>
      </WrapperRowHeader>
      { listProducts.map((el, idx) => {
        if (!el.isChild) {
          return (
            <RowWithParents
              data={ el }
              key={ idx }
              handleChange={ setListProducts }
              index={ idx }
              listProducts={ listProducts }
            />
          )
        } else {
          return (
            <>
              <RowItems
                title={ el.title }
                index={ idx }
                total={ el.total }
                price={ el.price }
                key={ idx }
                name={ el.name }
                handleChange={ setListProducts }
                listProducts={ listProducts }
              />
              <Line />
            </>
          )
        }
      }) }
      <DashboardFeatures />
      <Line />
      <DetailAmounts
        promotionalCode={ promotionalCode }
        setPromotionalCode={ setPromotionalCode }
        subtotal={ subtotal }
        vat={ memoizedVAT }
      />
      <WrapperGrandTotal>
        <h3>GRAND TOTAL</h3>
        <h1>
          <span style={{ fontWeight: '300' }}>IDR</span>{ ' ' }
          { promotionalCode.isApply
            ? maskedPrice((subtotal + memoizedVAT - promotionalCode.value).toString())
            : maskedPrice((subtotal + memoizedVAT).toString()) }
          <span style={{ fontWeight: '300', fontSize: '0.75em' }}>/month</span>
        </h1>
      </WrapperGrandTotal>
      <ButtonContainer>
        <Button type="warning">CONTINUE PAYMENT</Button>
      </ButtonContainer>
      <Contacts>
        <h3>Contact { /* +62 21 71793635 or */ }info@qlue.id for help</h3>
      </Contacts>
    </WrapperCard>
  )
}
export default DetailCard
