import React from "react";
import { WrapperQuantity, Title, MinButton, Quantity, PlusButton } from "./styles";

const QuantityButton = ({ total = 0, name = "units", handleChange, data }) => {
  const handlePlus = () => {
    const arrCopy = [...data.listProducts];
    const prevData = { ...arrCopy[data.index] };
    if (!data.isChild) {
      arrCopy[data.index] = { ...prevData, total: prevData.total + 1 };
      handleChange(arrCopy);
    } else {
      const previousChild = [...prevData.child];
      const pos = previousChild
        .map(function (e) {
          return e.title;
        })
        .indexOf(data.title);

      const previousChildData = { ...previousChild[pos] };
      previousChild[pos] = { ...previousChildData, total: previousChildData.total + 1 };

      arrCopy[data.index] = { ...prevData, child: previousChild };
      handleChange(arrCopy);
    }
  };

  const handleMin = () => {
    const arrCopy = [...data.listProducts];
    const prevData = { ...arrCopy[data.index] };
    if (!data.isChild) {
      arrCopy[data.index] = { ...prevData, total: prevData.total - 1 };
      handleChange(arrCopy);
    } else {
      const previousChild = [...prevData.child];
      const pos = previousChild
        .map(function (e) {
          return e.title;
        })
        .indexOf(data.title);

      const previousChildData = { ...previousChild[pos] };
      previousChild[pos] = { ...previousChildData, total: previousChildData.total - 1 };

      arrCopy[data.index] = { ...prevData, child: previousChild };
      handleChange(arrCopy);
    }
  };

  return (
    <WrapperQuantity>
      <Title>Quantity</Title>
      <MinButton onClick={total === 0 ? null : handleMin}>
        <span>-</span>
      </MinButton>
      <PlusButton onClick={handlePlus}>
        <span>+</span>
      </PlusButton>
      <Quantity>{total}</Quantity>
      {/* <Quantity>
        <Quantity.Title>Quantity</Quantity.Title>
        <Quantity.Number>{total}</Quantity.Number>
      </Quantity>
      <h3>{name}</h3>
      <PlusButton onClick={handlePlus}>
        <h2>+</h2>
      </PlusButton> */}
    </WrapperQuantity>
  );
};
export default QuantityButton;
